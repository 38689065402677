export function onError(error: ErrorWithResponse | { message: string }) {
	let message = error.toString()
	// Auth errors
	if (!(error instanceof Error) && error.message) {
		message = error.message
	}
	const rgErrorMessage = hasResponse(error)
		? `${message}
  response status: ${error?.response?.status}
  response headers: ${JSON.stringify(error?.response?.headers)}
  response data: ${JSON.stringify(error?.response?.data)}
  request data: ${JSON.stringify(error?.response?.request)}`
		: message
	let errorToSend = new Error(rgErrorMessage)
	if (isError(error)) {
		errorToSend.stack = error?.stack
	}
	window?.rg4js?.("send", {
		error: errorToSend,
	})

	alert(message)
}

function isError(e: any): e is Error {
	return e instanceof Error
}

function hasResponse(e: any): e is ErrorWithResponse {
	return e.response != null
}

type ErrorWithResponse = Error & { response: { status?: number; headers?: string; data?: string; request?: string } }
