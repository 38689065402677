import { createContext, useState, useContext } from "react"
import { PopUpMessage } from "./PopUpMessage/PopUpMessage"

export interface MessagingContextProps {
	messageContent: { icon: JSX.Element | null; text: string } | null
	setMessageContent: (content: { icon: JSX.Element | null; text: string }) => void
}

const MessagingContext = createContext<MessagingContextProps>({} as MessagingContextProps)

export const MessagingContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [messageContent, setMessageContent] = useState<{ icon: JSX.Element | null; text: string } | null>(null)

	return (
		<MessagingContext.Provider value={{ messageContent, setMessageContent }}>
			{children}
			<PopUpMessage>
				{messageContent && (
					<>
						{messageContent.icon} {messageContent.text}
					</>
				)}
			</PopUpMessage>
		</MessagingContext.Provider>
	)
}

export const useMessagingContext = () => useContext(MessagingContext)
