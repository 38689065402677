export function validatePassword(password) {
	return (
		/[a-z]/.test(password) && //must contain at least one lower case
		/[A-Z]/.test(password) && //must contain at least one upper case
		/[0-9]/.test(password) && //must contain at least one number
		/[_\W]/.test(password) && //must contain at least one special character
		/^[\S]+$/.test(password) && //must not contain white spaces
		password.length >= 8 //must be at least 8 characters
	)
}

export function validateEmail(email) {
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	) //Quick check of email format
}

/**
 * Takes a string and checks that it doesn’t only contain white spaces and has one or more characters
 * @param {string} name The name of the user
 */
export function validateUserName(name) {
	return (
		/[\S]/.test(name) && //must not contain only white spaces
		name?.length > 0 //must be at least 1 character
	)
}

export function correctHyperlink(link) {
	// add mailto: before email
	// enforce https:// before everything else
	if (link == null || link === "") return undefined // ensure no blank string

	if (link.startsWith("mailto:")) {
		return link
	}

	if (validateEmail(link)) {
		return "mailto:" + link
	}

	// assume web address
	return link.replace(/^https{0,1}:\/\/|^/, "https://")
}
