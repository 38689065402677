import { Children, cloneElement, useState, useEffect, ReactElement, ReactNode } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import "./AraTooltip.css"
import { Placement } from "@popperjs/core"

export interface AraTooltipProps {
	tooltipLabel?: ReactNode
	holdOn?: boolean
	hide?: boolean
	placement?: Placement
	highlight?: boolean
	showOnHover?: boolean
	timeToShow?: number
	timeToHide?: number
	children?: ReactElement
	className?: string
	style?: React.CSSProperties
	asDiv?: boolean
}

export function AraTooltip({
	tooltipLabel,
	holdOn = false,
	hide = false,
	placement = "right",
	highlight = false,
	showOnHover = true,
	timeToShow = 250,
	timeToHide = 400,
	children,
	className = "",
	asDiv = false,
	...props
}: AraTooltipProps) {
	const showable = tooltipLabel != null && tooltipLabel !== "" && !hide
	const canShow = () => holdOn && showable
	const [show, setShow] = useState(canShow())
	const [focus, setFocus] = useState(false)
	const showHighlight = highlight && show && !focus

	useEffect(() => {
		setShow(canShow())
	}, [holdOn, showable])
	return (
		<OverlayTrigger
			onToggle={(nextShow) => {
				showOnHover && setShow((nextShow || holdOn) && showable)
			}}
			show={show}
			placement={placement}
			delay={{ show: timeToShow, hide: timeToHide }}
			overlay={<Tooltip className={`AraTooltip ${className}`}>{tooltipLabel}</Tooltip>}>
			{
				<span style={{ width: "fit-content" }} className={`tooltip-wrap${showHighlight ? "-highlighted" : ""}`}>
					{Children.toArray(children).map((child: ReactElement) =>
						cloneElement(child, {
							onFocus: (e) => {
								setFocus(true)
								child.props?.onFocus?.(e)
							},
							onBlur: (e) => {
								setFocus(false)
								child.props?.onBlur?.(e)
							},
						})
					)}
				</span>
			}
		</OverlayTrigger>
	)
}

export function HoverTooltip({ ...props }) {
	return AraTooltip({ ...props, holdOn: false, highlight: false })
}
