import * as stringLib from "./stringLib"

export function capitalizeWord(string: string, lowercasing: boolean = true): string {
	const lower = (stringIn) => (lowercasing ? stringIn.toLowerCase() : stringIn)
	return string.charAt(0).toUpperCase() + lower(string.slice(1, string.length))
}

export function capitalizeString(string: string, lowercasing: boolean = true): string {
	const parts = string.split(" ")
	return parts.map((part) => capitalizeWord(part, lowercasing)).join(" ")
}

export function capitalize<T>(obj: T, lowercasing: boolean = true): string | T {
	if (typeof obj === "string") {
		return stringLib.capitalizeString(obj, lowercasing)
	} else {
		return obj
	}
}

export const standardBulletSymbol: string = "• "
const bullets: string[] = [
	"",
	"",
	"•",
	"§",
	"■",
	"❖",
	"●",
	"➢",
	"",
	"",
	"·",
	"",
	"",
	"",
	"",
	"",
	"",
	"•",
	"",
	"◦",
	"✓",
	"♦",
	"◆",
	"",
	"❑",
	"»",
	"→",
	"✔",
]
const strictBullets: string[] = ["*", "\\p{General_Category=Co}", "\\p{General_Category=So}"]
const strictestBullets: string[] = ["-", "—", "–", "o"] //three different hyphen symbols

const basicRegex: string = `\\s*[${bullets.join("")}]\\s*` // symbol with optional whitespace before and after
const strictRegex: string = `(?:^|\\s+)(?!\\p{Extended_Pictographic})[${strictBullets.join("")}]\\s+` // symbol with non-optional whitespace before and after or placed at start of line with non-optional whitespace after

const strictestSubRegex: string = `\\n\\s*[${strictestBullets.join("")}]\\s+`

const strictestRegexStartingLine: string = `^[${strictestBullets.join("")}]\\s+(?=.*${strictestSubRegex})`
const strictestRegex = `${strictestRegexStartingLine}|${strictestSubRegex}` // symbol with new line before and whitespace after or placed at start of line but when a bullet can be found later in the text

export const bulletRegex: RegExp = new RegExp(`${basicRegex}|${strictRegex}|${strictestRegex}`, "gmsu")
// should include whitespace

export const bulletSectionsRegex: RegExp = new RegExp(
	`((?:(?:${basicRegex}|${strictRegex}|${strictestRegex}).+?(?:\n\\s*[^A-Z].*)*(?:[\n\r]|$))+)`,
	"gmu"
)
// export const getBulletSections: string = (text) => {
// 	if (text) {
// 		return text.split(bulletSectionsRegex)
// 	} else {
// 		return text
// 	}
// }

export function processBulletPoints(text: string): string {
	if (text) {
		return text.replace(bulletRegex, standardBulletSymbol)
	} else {
		return text
	}
}

export function stripBulletPoints(text: string): string {
	if (text) {
		return text.replace(bulletRegex, "")
	} else {
		return text
	}
}

export function limitStringLength(text: string, maxCharacters: number): string {
	// Avoid changing cutStringBefore
	if (text == null || text?.length <= maxCharacters) return text
	//arriving here means text needs cutting
	const firstCut = text.slice(0, maxCharacters)
	const cutToLastSpace = firstCut.slice(0, firstCut.lastIndexOf(" "))
	if (cutToLastSpace.length > maxCharacters - 10) {
		return cutToLastSpace + "..."
	} else {
		return firstCut + "..."
	}
}

const wordCounterRegex: RegExp = new RegExp("\\S+", "g")

export function wordCounter(text: string): number {
	if (text == null || typeof text !== "string") {
		return 0
	}
	const matches = text.match(wordCounterRegex) || []
	return matches.length
}

export const basicTextProcessor = (text: string): string => processBulletPoints(text)

export const basicHeadingProcessor = <T>(text: T): T => text

/**Returns a random sequence of lowercase alphabetic characters [with a length equal to the length param] */
export const randomString = (length: number): string =>
	Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.slice(2, 2 + length)

export const randomHexString = (length: number): string =>
	Math.random()
		.toString(16)
		.slice(2, 2 + length)

export const camelCaseToSpaced = (text: string): string => text.replace(/([A-Z])/g, " $1")

export const spacedToCamelCase = (text: string): string =>
	text.replace(/\s(\w)|^(\w)/g, (a, b, c) => `${b?.toUpperCase() ?? ""}${c?.toLowerCase() ?? ""}`)

export const DEFAULT_BULLET_SYMBOL = standardBulletSymbol.trim()

export const DEFAULT_SUB_BULLET_SYMBOL = "o"

export const DEFAULT_BULLET_COLOR = "#000000"

export const DEFAULT_BASE_BULLET_SPACING_LEFT = "0.5" // em value
export const DEFAULT_BULLET_SPACING_LEFT = "1" // em value
export const DEFAULT_BULLET_SPACING_RIGHT = "0.4" // em value

export const DEFAULT_FONT_CASE = "None"

export const DEFAULT_LINE_HEIGHT = 1.5

export const defaultFileName = (includeName: boolean): string =>
	`[{\"type\":\"paragraph\",\"children\":[{\"text\":\"\"},{\"type\":\"tagging\",\"tagging\":\"${
		includeName !== false ? "candidateName" : "identifer"
	}\",\"nodeType\":\"inline\",\"children\":[{\"text\":\"${
		includeName !== false ? "@Candidate Name" : "@Identifer"
	}\",\"marked\":true}]}]}]`
