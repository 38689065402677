import * as objLib from "./objLib"

/** Takes an object and returns a new object including only key value pairs specified by keys param passed */
export function makeReducedObj(obj, keys, removeNull = false) {
	const reducedObj = keys.reduce((acc, val) => {
		acc[val] = obj[val]
		return acc
	}, {})
	return objLib.cleanObject(reducedObj, removeNull)
}

/** Takes an object comprised of multiple objects, nested to one layer, and returns a new nested object including only key value pairs specified by passed keys param. */
export function makeReducedObjOfObj(obj, keys) {
	const reducedObj = Object.keys(obj).reduce((acc, val) => {
		acc[val] = makeReducedObj(obj[val], keys)
		return acc
	}, {})
	return objLib.cleanObject(reducedObj)
}

/** Takes object and returns new object, removing key value pairs with undefined (& null if param set true) values in nested object with nested arrays */
export function cleanObject(obj, removeNull = false) {
	if (typeof obj === "object" && obj !== null) {
		if (Array.isArray(obj)) {
			return obj
				.map((item) => cleanObject(item, removeNull))
				.filter((item) => {
					if (removeNull) {
						return item !== null && item !== undefined
					}
					return item !== undefined
				})
		}
		return Object.fromEntries(
			Object.entries(obj)
				.map(([k, v]) => [k, cleanObject(v, removeNull)])
				.filter(([k, v]) => {
					if (removeNull) {
						return v !== null && v !== undefined
					}
					return v !== undefined
				})
		)
	}
	return obj
}

/** Utility class that creates an internal object for reverse mapping */
export class TwoWayMap {
	constructor(forwardMap, defaultKey, defaultValue) {
		this.resetAll(forwardMap)
		this.defaultValue = defaultValue
		this.defaultKey = defaultKey
	}

	resetAll(forwardMap) {
		this.forwardMap = { ...forwardMap }
		this.backwardMap = Object.entries(forwardMap).reduce((bwdMap, [key, value]) => {
			bwdMap[value] = key
			return bwdMap
		}, {})
	}

	forward(key) {
		return this.forwardMap[key] ?? this.defaultValue
	}

	backward(value) {
		return this.backwardMap[value] ?? this.defaultKey
	}

	get length() {
		return Object.keys(this.forwardMap).length
	}

	get keys() {
		return Object.keys(this.forwardMap)
	}

	get values() {
		return Object.values(this.forwardMap)
	}

	set(key, value) {
		this.forwardMap[key] = value
		this.backwardMap[value] = key
	}

	deleteByKey(key) {
		const value = this.forwardMap[key]
		delete this.forwardMap[key]
		delete this.backwardMap[value]
	}

	deleteByValue(value) {
		const key = this.backwardMap[value]
		delete this.forwardMap[key]
		delete this.backwardMap[value]
	}
}

/** Takes an object and returns that object with key value pairs with null or undefined values removed. */
export function convertUndefinedToNull(obj, recursive = true) {
	// For use with json where null values should be sent
	if (recursive) {
		Object.entries(obj).forEach(([k, v]) => {
			typeof v === "object" && v !== null ? convertUndefinedToNull(v, recursive) : (obj[k] = v ?? null)
		})
	} else {
		Object.entries(obj).forEach(([k, v]) => {
			obj[k] = v ?? null
		})
	}
	return obj
}

/** Takes an object and returns new object with key value pairs with undefined values removed. */
export const stripUndefined = (obj) => {
	if (typeof obj === "object" && obj !== null) {
		return Object.fromEntries(
			Object.entries(obj)
				.map(([k, v]) => {
					if (isReactSymbol(v)) {
						return [k, v]
					}
					return [k, stripUndefined(v)]
				})
				.filter(([k, v]) => v !== undefined)
		)
	}
	return obj
}

function isReactSymbol(obj) {
	return (
		typeof obj?.["$$typeof"] === "symbol" &&
		(obj["$$typeof"] === Symbol.for("react.provider") ||
			obj["$$typeof"] === Symbol.for("react.element") ||
			obj["$$typeof"] === Symbol.for("react.context"))
	)
}

/** Takes two objects and returns true if obj1 and obj2 have strict equality. Handles Array cases and nested arrays in object. In event of comparing react elements will only compare keys and props */
export function isObjEqual(obj1, obj2) {
	if (typeof obj1 !== typeof obj2) {
		return false
	}
	if (obj1 == null || obj2 == null) {
		return obj1 === obj2
	} // avoid issues caused by typeof null === 'object' should return true for undefined undefined or null null but nothing else
	if (isReactSymbol(obj1)) {
		return isObjEqual(obj1.key, obj2.key) && isObjEqual(obj1.props, obj2.props)
	}
	if (Array.isArray(obj1) || Array.isArray(obj2)) {
		return (
			obj1?.length === obj2?.length &&
			obj1.every((el, idx) => {
				const unchanged = isObjEqual(el, obj2?.[idx])
				return unchanged
			})
		)
	}
	if (typeof obj1 === "object") {
		const obj1Strip = stripUndefined(obj1) // strip undefined to avoid difference between {} and {a: undefined}
		const obj2Strip = stripUndefined(obj2)
		//It may be faster to just remove length comparison instead of stripping undefined??! Probably doesn't matter
		const unchanged =
			Object.keys(obj1Strip)?.length === Object.keys(obj2Strip)?.length &&
			Object.keys(obj1Strip).every((key) => {
				const unchanged = isObjEqual(obj2Strip?.[key], obj1Strip?.[key])
				return unchanged
			})
		return unchanged
	}
	return obj1 === obj2
}

/**
 * Utility class for handling header data
 */
export class HeaderHandler {
	constructor(headerInfo = {}, numberOfSections = 3) {
		this.numberOfSections = numberOfSections
		this.keys = []
		for (var i = 1; i < 8; i++) {
			if (i === 5) {
				continue
			}
			if (headerInfo[i] != null) {
				this[i] = { size: 1, ...headerInfo[i], key: i }
				this.keys.push(i)
			}
		}
	}

	get info() {
		return this.keys.reduce((out, key) => {
			out[key] = this[key]
			return out
		}, {})
	}

	get length() {
		return this.keys.length
	}

	get sum() {
		return this.keys.map(Number).reduce((sum, key) => sum + key, 0)
	}

	get binary() {
		return getBinary(this.sum).padStart(3, "0").split("")
	}

	get blanks() {
		return this.binary.map((a) => a === "0").reverse()
	}

	get indexedData() {
		// returns {index, size(in blocks), info}, for each non blank starting index
		return this.blanks
			.map((blank, index) => {
				if (blank) {
					return null
				}
				const key = this.keyForIndex(index)
				if (key == null) {
					return null
				}
				const size = getBinary(key).replaceAll("0", "").length
				return { index, size, info: this[key] }
			})
			.filter((a) => a != null)
			.reduce((a, b) => {
				a[b.index] = b
				return a
			}, {})
	}

	keyForIndex(index) {
		// get the key of the data whose left side is at the index (0 can be 1,3,7) (1 can be 2,6) (2 can be 4)
		index = parseInt(index)
		var checkOffset = 0
		var checkingKey = 2 ** index
		while (checkOffset + index < this.numberOfSections) {
			if (this[checkingKey] != null) {
				return checkingKey
			}
			checkOffset += 1
			checkingKey += 2 ** (index + checkOffset)
		}
		return null
	}

	updateKeys() {
		this.keys = Object.keys(this).filter((k) => !isNaN(k))
	}

	expandLeft(index, by = 1) {
		const lhIndex = index - by
		if (!this.blanks[lhIndex] || lhIndex < 0 || (by === 2 && !this.blanks[1])) {
			return
		} //can't expand
		const item = this.deleteAtIndex(index)
		this.addAtIndex(lhIndex, { ...item, size: (item.size ?? 1) + by })
	}

	collapseLeft(index, by = 1) {
		const currentSize = this.indexedData[index].size ?? 1
		if (currentSize - by < 1) {
			return
		} //can't collapse
		const item = this.deleteAtIndex(index)
		const newIndex = index + by
		this.addAtIndex(newIndex, { ...item, size: item.size - by })
	}

	expandRight(index, by = 1) {
		const currentSize = this.indexedData[index].size ?? 1
		const rhIndex = currentSize + index + by - 1
		if (!this.blanks[rhIndex] || rhIndex > 2 || (by === 2 && !this.blanks[1])) {
			return
		} //can't expand
		const item = this.deleteAtIndex(index)
		this.addAtIndex(index, { ...item, size: (item.size ?? 1) + by })
	}

	collapseRight(index, by = 1) {
		const currentSize = this.indexedData[index].size ?? 1
		if (currentSize - by < 1) {
			return
		} //can't collapse
		const item = this.deleteAtIndex(index)
		this.addAtIndex(index, { ...item, size: item.size - by })
	}

	moveItem(oldIndex, newIndex) {
		if (oldIndex === newIndex) {
			return
		}
		const removedItem = this.deleteAtIndex(oldIndex)
		this.addAtIndex(newIndex, removedItem)
		this.updateKeys()
	}

	addAtIndex(index, info) {
		index = parseInt(index)
		let size = Math.min(3 - parseInt(index), parseInt(info.size ?? 1))
		const blanks = this.blanks
		while (size > 1 && !blanks[size + index - 1]) {
			//rh index overlaps content, reduce size if possible
			size -= 1
		}
		const key = 2 ** (size + parseInt(index)) - 2 ** index
		this[key] = { ...info, size, key }
		this.updateKeys()
	}

	deleteAtIndex(index, updateKeysAfter = true) {
		const deleteKey = this.keyForIndex(index)
		if (deleteKey != null) {
			const infoToDelete = this[deleteKey]
			delete this[deleteKey]
			if (updateKeysAfter) this.updateKeys()
			return infoToDelete
		}
		return null
	}
}

/** Takes a number and returns a string of the binary representation of that number */
export function getBinary(number) {
	return number.toString(2)
}

/** Creates deep copy of object, whose properties do not share the same references */
export function deepCopy(obj) {
	if (obj === null) return obj
	if (Array.isArray(obj)) {
		return obj.map((e) => deepCopy(e))
	}
	if (typeof obj === "object" && !isReactSymbol(obj)) {
		return Object.entries(obj).reduce((newObj, [k, v]) => {
			newObj[k] = deepCopy(v)
			return newObj
		}, {})
	}
	return obj // non-reference input (or react symbol) just return
}

/** Combines two objects, mergring properties into a single object, prioritising values from second object in event of conflicts */
export function recursiveCombineObj(oldObj, newObj) {
	if (oldObj == null || newObj === undefined) {
		return newObj ?? oldObj
	}
	if (Array.isArray(oldObj)) {
		return newObj
	}
	if (typeof oldObj === "object") {
		const keys = Object.keys({ ...oldObj, ...newObj })
		return keys.reduce((objOut, key) => ({ ...objOut, [key]: recursiveCombineObj(oldObj[key], newObj[key]) }), {})
	}
	return newObj
}

/** Returns the value at specified path in object */
export const getValueAtPath = (obj, path) => {
	let returnValue = obj ?? {}
	for (var key of path) {
		returnValue = (returnValue ?? {})[key]
	}
	return returnValue
}

/** Sets the value at specified path in object */
export function setValueAtPath(obj, [...path], value) {
	const key = path.pop()
	let innerObjToSet = obj
	for (var pathKey of path) {
		if (pathKey in innerObjToSet && innerObjToSet[pathKey] != null) {
			innerObjToSet = innerObjToSet[pathKey]
		} else {
			const oldObj = innerObjToSet
			innerObjToSet = {}
			oldObj[pathKey] = innerObjToSet
		}
	}
	innerObjToSet[key] = value
}

/** Returns true if obj1 is subset of obj2. Handles array cases and nested array cases. */
export function objIsSubset(obj1, obj2, equateNullish = true) {
	if (equateNullish && obj1 == null && obj2 == null) return true
	if (typeof obj1 !== typeof obj2) return false
	if (obj1 == null || obj2 == null) return obj1 === obj2
	if (Array.isArray(obj1) || Array.isArray(obj2)) {
		if (!Array.isArray(obj1) || !Array.isArray(obj2) || obj1.length > obj2.length) return false
		return obj1.every((el, idx) => objIsSubset(el, obj2[idx]))
	}
	if (typeof obj1 === "object") {
		const keys1 = Object.keys(obj1)
		const keys2 = Object.keys(obj2)
		if (keys1.length > keys2.length) return false
		return keys1.every((key) => {
			const unchanged = objIsSubset(obj2[key], obj1[key])
			return unchanged
		})
	}
	return obj1 === obj2
}
