import { lazy } from "react"

export const onSuccessfulImport = (output) => {
	window.localStorage.setItem("page-has-been-force-refreshed", "false")
	return output
}
export const onFailedImport = (error) => {
	console.log("load failed")
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.localStorage.getItem("page-has-been-force-refreshed") || "false"
	)
	if (!pageHasAlreadyBeenForceRefreshed) {
		// Assuming that the user is not on the latest version of the application.
		// Let's refresh the page immediately.
		window.localStorage.setItem("page-has-been-force-refreshed", "true")
		console.log("forcing refresh")
		window.location.reload()
		return { default: () => "" }
	}
	console.log("already attempted refresh, throwing error")
	// The page has already been reloaded
	// Assuming that user is already using the latest version of the application.
	// Let's let the application crash and raise the error.
	throw error
}

export const lazyWithForcedRefresh = (importStatement) => {
	return lazy(() => importStatement().then(onSuccessfulImport).catch(onFailedImport))
}
