import { BrowserStorageCache } from "@aws-amplify/cache"

const reloadConfig = {
	defaultTTL: 1000 * 10,
	storage: window.sessionStorage,
	keyPrefix: "reloadCache",
}

let reloadCache

function getReloadCache() {
	if (!reloadCache) {
		reloadCache = BrowserStorageCache.createInstance(reloadConfig)
	}
	return reloadCache
}

export function safeReload(storageValue: string) {
	const cache = getReloadCache()
	if (!cache.getItem(storageValue)) {
		cache.setItem(storageValue, true)

		console.log("forcing refresh")
		window.location.reload()
	} else {
		console.log("already attempted refresh")
	}
}
