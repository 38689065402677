import signUpImage from "../../images/sign-up.png"
import { SignUpCard } from "./SignUpCard/SignUpCard"
import "./SignUpCardWithImage.css"

export function SignUpCardWithImage({
	children,
	size,
}: {
	children: React.ReactNode
	size?: "small" | "large" | "medium"
}) {
	const signUpImageNode = (
		<img
			id="card-sign-up-img"
			src={signUpImage}
			alt="Sign up"
			style={{
				objectFit: "cover",
				width: `${size === "large" ? "117%" : "110%"}`,
				maxWidth: `${size === "large" ? "37rem" : "34rem"}`,
			}}
		/>
	)

	return (
		<div className="sign-up-card-with-image">
			<SignUpCard
				size={size}
				imageContainerStyle={{
					background: "linear-gradient(211deg, #fff 7.74%, #2fbad1 54.59%, #1e1751 97.41%)",
					boxShadow: "0px 4px 34px rgba(0, 0, 0, 0.15)",
					borderRadius: "0 50px 0 0",
					maxWidth: `${size === "large" ? "min(33rem, 40%)" : "min(30rem, 40%)"}`,
				}}
				image={signUpImageNode}
				logo={true}>
				{children}
			</SignUpCard>
		</div>
	)
}
