import "./Modal.css"
import { useModalContext } from "./ModalContext"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import closeIcon from "../images/close.svg"

export function Modal({ showPreClose, setShowPreClose, children }) {
	const { setModalContent, modalOptions } = useModalContext()
	const { pathname } = useLocation()
	useEffect(() => {
		// navigation triggered remove modal
		setModalContent(null)
	}, [pathname, setModalContent])

	useEffect(() => {
		const overflow = children == null ? "auto" : "hidden"
		document.body.style.overflow = overflow
	}, [children])
	if (children == null) return null

	const onClickOut = (e) => {
		if (showPreClose) {
			return
		} // prevent closing without interacting with warning
		if (modalOptions?.PreCloseScreen != null) {
			console.log("showing preclose")
			setShowPreClose(true)
		} else {
			setModalContent(null)
		}
	}

	return (
		<div
			onClick={(e) => {
				if (modalOptions?.clickOutAnywhere) {
					onClickOut(e)
				}
			}}
			className="ara-modal">
			{!showPreClose && (
				<div onClick={onClickOut} className="ara-modal-close clickable">
					<img src={closeIcon} alt="close" width={"40"} color="grey" />
				</div>
			)}
			{children}
			{showPreClose && modalOptions?.PreCloseScreen != null ? (
				<modalOptions.PreCloseScreen
					stay={(e) => {
						e.stopPropagation()
						setShowPreClose(false)
					}}
					leave={(e) => {
						e.stopPropagation()
						setShowPreClose(false)
						setModalContent(null)
					}}
				/>
			) : null}
		</div>
	)
}
