import { useMemo, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useMountEffect } from "../hooksLib/hooksLib"

export function useQuery() {
	return new URLSearchParams(useLocation().search)
}

export function useRemoveParams(...paramNames: Array<string>) {
	const [paramValue, setParamValue] = useState<object>({})
	const history = useHistory()
	const queryParams = new URLSearchParams(useLocation().search)

	useMountEffect(() => {
		let hasChanged = false
		paramNames.forEach((paramName) => {
			if (queryParams.has(paramName)) {
				const thisParamValue = queryParams.get(paramName)
				console.log(thisParamValue)
				if (thisParamValue != null && thisParamValue !== paramValue[paramName]) {
					console.log("setting param state", thisParamValue)
					setParamValue((paramValue) => ({ ...paramValue, [paramName]: thisParamValue }))
					queryParams.delete(paramName)
					hasChanged = true
				}
			}
		})
		if (hasChanged) {
			console.log("removing params")
			history.replace({
				search: queryParams.toString(),
			})
		}
	})
	return paramValue
}
