import { useMountEffect, getOAuthStatus, capitalize } from "libs"

export function OAuthCallback() {
	const query = new URLSearchParams(window.location.search)

	const parent = window.parent as Window
	const state = JSON.parse(query.get("state"))
	const { integrationData, platformPageProps, sig, clientId } = state

	useMountEffect(() => {
		if (platformPageProps?.newTab) {
			// new tab flow
			console.log("sending ready message to oauth channel")
			getOAuthStatus(
				{
					authCode: query.get("code"),
					platform: integrationData.platform,
					externalUserId: integrationData.userId,
					externalClientId: integrationData.externalClientId,
					clientId,
				},
				sig
			)
				.then((response) => {
					if (response) {
						// success, close the window
						window.close()
					} else {
						throw new Error("Error authorizing Hireara, false value returned from api")
					}
				})
				.catch((e) => {
					console.error(e)
					alert(`Error authorizing Hireara access to ${capitalize(integrationData.platform)}`)
				})
		} else {
			console.log("sending message to parent")
			parent?.postMessage({ query: Object.fromEntries(query.entries()) }, "*")
		}
	})

	return <div></div>
}
