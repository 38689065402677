import { useState } from "react"
import { useMountEffect } from "libs"
import { Link, useLocation } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Row from "react-bootstrap/Row"
import NavDropdown from "react-bootstrap/NavDropdown"
import { setTitle } from "../SetDocumentTitle/SetDocumentTitle"
import araLogo from "../../images/HireAraLogoPurple.png"
import "./AppSkeleton.css"
import "../CardView/card-view.css" // import here to ensure in main bundle
import "../CVViewing/CVCards/CVCards.css" // import here to ensure in main bundle
import { createRef } from "react"

export const mouseWithinWindow: React.MutableRefObject<boolean> = createRef<boolean | undefined>()

export function TopLevel({ isAuthenticating, ...props }) {
	useMountEffect(() => {
		mouseWithinWindow.current = true
		const onMouseLeave = (e) => {
			mouseWithinWindow.current = false
		}
		const onMouseEnter = (e) => {
			mouseWithinWindow.current = true
		}
		document.addEventListener("mouseleave", onMouseLeave)
		document.addEventListener("mouseenter", onMouseEnter)
		return () => {
			document.removeEventListener("mouseleave", onMouseLeave)
			document.removeEventListener("mouseenter", onMouseEnter)
		}
	})

	return (
		!isAuthenticating && (
			<div className="AppContainer">
				<div className="main">{props.children}</div>
			</div>
		)
	)
}

export function AppSkeleton({
	logoLink,
	navbar = true,
	navbarDisabled = false,
	navbarLinks,
	navbarContent = null,
	isAuthenticating,
	logo,
	userName,
	handleLogout,
	...props
}) {
	const linkProps = logoLink ? { as: Link, to: logoLink } : {}
	const { pathname } = useLocation()
	const [expanded, setExpanded] = useState(false)

	useMountEffect(() => {
		setTitle()
	})

	return (
		<TopLevel isAuthenticating={isAuthenticating}>
			{navbar ? (
				<Navbar id="navbar" className={"justify-content-start"} collapseOnSelect expand="md" expanded={expanded}>
					<Navbar.Brand {...linkProps} style={{ marginRight: 10 }} className={navbarDisabled ? "nav-disabled" : ""}>
						<img
							style={{ width: 120, height: 30, objectFit: "contain" }}
							height="30px"
							width="120px"
							className="d-inline-block align-top clickable"
							src={araLogo}
							alt="ARA"
						/>
					</Navbar.Brand>
					<Navbar.Collapse role="navbar" className="justify-content-start">
						<Nav>
							{Object.entries(navbarLinks).map(([text, linkPath]) => (
								<Nav.Link
									className={navbarDisabled ? "nav-disabled" : ""}
									key={text}
									as={Link}
									to={linkPath}
									onClick={() => {
										setExpanded(false)
									}}>
									<span className={`blueLink ${pathname.startsWith(linkPath) ? "currentPageUnderline" : ""}`}>
										{text}
									</span>
								</Nav.Link>
							))}
						</Nav>
						<Nav style={{ flex: "10 0 auto" }} className="justify-content-end">
							<NavDropdown
								className="my-auto darkLink"
								style={{ fontSize: 15, minWidth: 200, textAlign: "right" }}
								title={`Hello, ${userName}`}>
								<NavDropdown.Item className="darkLink" onClick={handleLogout}>
									Sign Out
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
					<Nav style={{ fontSize: 15, marginLeft: "auto" }}>
						<Nav.Item>
							{logo ? (
								<img
									height="60px"
									style={{
										height: 60,
										maxWidth: 145,
										objectFit: "contain",
									}}
									alt="logo"
									src={logo}></img>
							) : (
								<div style={{ height: 60 }}></div>
							)}
						</Nav.Item>
					</Nav>
					<Navbar.Toggle
						onClick={() => {
							setExpanded(!expanded)
						}}
					/>
				</Navbar>
			) : (
				<Navbar.Brand {...linkProps}>
					<img
						style={{ width: 100, margin: "8px 16px" }}
						// height="30"
						// className="d-inline-block align-top"
						src={araLogo}
						alt="ARA"
					/>
				</Navbar.Brand>
			)}
			{navbarContent && (
				<Row className="justify-content-end" style={{ marginBottom: 20 }}>
					{navbarContent}
				</Row>
			)}
			{props.children}
		</TopLevel>
	)
}
