import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter as Router } from "react-router-dom"
import { Amplify } from "@aws-amplify/core"
import { config } from "./config"

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolId: config.cognito.USER_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
	},
	API: {
		endpoints: [
			{
				name: config.apiGateway.NAME,
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				name: config.apiGatewayPublic.NAME,
				endpoint: config.apiGatewayPublic.URL,
				region: config.apiGatewayPublic.REGION,
			},
			{
				name: config.apiGatewayCustomPublic.NAME,
				endpoint: config.apiGatewayCustomPublic.URL,
				region: config.apiGatewayCustomPublic.REGION,
			},
			{
				name: config.apiGatewayLinks.NAME,
				endpoint: config.apiGatewayLinks.URL,
				region: config.apiGatewayLinks.REGION,
			},
		],
	},
})

createRoot(document.getElementById("root")).render(
	<Router>
		<App />
	</Router>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
