// shamelessly stolen from https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
export function getBase64(file: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			const encoded = reader.result?.toString() || ""
			resolve(encoded)
		}
		reader.onerror = (error) => reject(error)
	})
}

export function stripB64Extra(b64File: string): string {
	let encoded = b64File.replace(/^data:(.*,)?/, "")
	if (encoded.length % 4 > 0) {
		encoded += "=".repeat(4 - (encoded.length % 4))
	}
	return encoded
}
