import { Component, ReactNode } from "react"
import "./ErrorBoundary.css"
import arrow from "../../images/template-arrow.png"

export class ErrorBoundary extends Component<{ hasChat?: boolean; children: ReactNode }, { hasError: boolean }> {
	constructor(props: { hasChat?: boolean; children: ReactNode }) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		window?.rg4js?.("send", {
			error: error,
		})
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="error-boundary-div">
					<div className="error-title">Oh no, something went wrong. 😔</div>
					<div>We're sorry about that</div>
					<div className="error-boundary-text">Please try refreshing the page.</div>
					{(this.props.hasChat ?? true) && (
						<div className="error-chat-message-box">
							<div>Ahh, refreshing didn't help? Get in contact with us on chat and we'll get this sorted.</div>
							<img className="error-chat-arrow" src={arrow} alt="arrow"></img>
						</div>
					)}
				</div>
			)
		}

		return this.props.children
	}
}
