import "./SignUpForm.css"
import { NewStandardButton } from "../../../Buttons/StandardButton"
import { SignUpInputField, SignUpInputFieldProps } from "../SignUpInputField/SignUpInputField"

import { ReactNode } from "react"
import { SignUpSelectField } from "../SignUpSelectField/SignUpSelectField"

interface SignUpFormProps {
	fields: SignUpInputFieldProps[]
	title?: ReactNode
	button?: { text: string; role?: string; onClick?: (event: any) => void; disabled?: boolean; isLoading?: boolean }
}
export function SignUpForm({ fields, button, title }: SignUpFormProps) {
	return (
		<div className="sign-up-form">
			<form>
				{title && <div className="sign-up-form-title">{title}</div>}
				{fields.map((field) =>
					field.type === "select" ? (
						<SignUpSelectField
							placeholder={field.placeholder}
							key={field.fieldName}
							label={field.label}
							fieldName={field.fieldName}
							options={field.dropdownOptions}
							required={field.required}
							updateField={field.inputFieldHandler}
							selectedItemStyle={field.selectedItemStyle}
						/>
					) : (
						<SignUpInputField
							key={field.fieldName}
							label={field.label}
							type={field.type}
							placeholder={field.placeholder}
							initialValue={field.initialValue}
							inputFieldHandler={field.inputFieldHandler}
							fieldName={field.fieldName}
							required={field.required}
							disabled={field.disabled}
							description={field.description}
							inputMode={field.inputMode}
							autofocus={field.autofocus}
							autocomplete={field.autocomplete}
							passwordAlert={field.passwordAlert}
						/>
					)
				)}
				{button && (
					<NewStandardButton
						isLoading={button.isLoading}
						role={button.role}
						size="full-width"
						variant="primary"
						disabled={button.disabled}
						onClick={button.onClick}
						className="sign-up-button">
						{button.text}
					</NewStandardButton>
				)}
			</form>
		</div>
	)
}
