import { Route, Redirect } from "react-router-dom"
import { useConfigContext, useLoadContext } from "libs"
import { useQuery } from "libs"

export function UnauthenticatedRoute({ children, ...rest }) {
	const { user } = useLoadContext()
	const qsParams = useQuery()
	const redirect = qsParams.get("redirect")
	const { homepage } = useConfigContext()
	return (
		<Route {...rest}>
			{!user.isAuthenticated ? children : <Redirect to={redirect === "" || redirect === null ? homepage : redirect} />}
		</Route>
	)
}
