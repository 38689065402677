import { Dropdown } from "react-bootstrap"
import "./SignUpSelectField.css"
import { useState } from "react"

export interface SignUpSelectFieldProps {
	options: string[] | { text: JSX.Element; key: string }[]
	placeholder: string | JSX.Element
	label: string | JSX.Element
	required?: boolean
	updateField?: (option) => void
	fieldName: string
	selectedItemStyle?: React.CSSProperties
	size?: "small" | "medium" | "full-width"
	labelPosition?: "top" | "inline"
}

export function SignUpSelectField({
	updateField,
	options,
	label,
	fieldName,
	required = false,
	placeholder,
	selectedItemStyle,
	size = "full-width",
	labelPosition = "top",
}: SignUpSelectFieldProps) {
	const [show, setShow] = useState(false)
	const [selectedOption, setSelectedOption] = useState(placeholder)
	const allowToggle = ["select", "click", "rootClose", "keydown"]
	const handleSelect = (option: string) => {
		setSelectedOption(option)
		updateField({ [fieldName]: option })
	}
	return (
		<div className={`sign-up-select sign-up-select-label-position-${labelPosition}`}>
			<label>
				{label} {required && <span style={{ color: "var(--ara-red" }}>{" *"}</span>}
			</label>
			<Dropdown
				className={`sign-up-select-${size}`}
				show={show}
				onToggle={(shouldShow: boolean, metaData: { originalEvent; source }) => {
					const e = metaData.originalEvent
					if (allowToggle.includes(metaData.source)) {
						setShow(shouldShow)
					} else {
						console.log(metaData.source)
						if (e.key === " ") {
							console.log("space")
							e.preventDefault()
						}
					}
				}}>
				<Dropdown.Toggle className="sign-up-select-toggle">
					<span style={selectedItemStyle}>{selectedOption}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu className="sign-up-select-menu">
					{options.map((option) => {
						if (option.text) {
							return (
								<Dropdown.Item onClick={() => handleSelect(option.key)} key={option.key}>
									{option.text}
								</Dropdown.Item>
							)
						} else {
							return (
								<Dropdown.Item onClick={() => handleSelect(option)} key={option}>
									{option}
								</Dropdown.Item>
							)
						}
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
