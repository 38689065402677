export const setTitle = () => {
	const currentUrl = window.location.href
	if (currentUrl == null || currentUrl === "" || currentUrl.includes("localhost")) {
		if (document.title?.endsWith(" - Dev")) {
			return
		}
		document.title = document.title + " - Dev"
		return
	}
	Object.entries(TITLES).forEach(([urlContent, title]) => {
		if (currentUrl.includes(urlContent)) {
			if (document.title?.includes("-")) {
				return
			}
			document.title = document.title + " - " + title
		}
	})
}

const TITLES = {
	testbranchzero: "Test Site Zero",
	testbranchtwo: "Test Site Two",
	testbranchthree: "Test Site Three",
	localhost: "Dev",
	t1: "Test Site One",
	t2: "Test Site Two",
	t3: "Test Site Three",
	t4: "Test Site Four",
	t5: "Test Site Five",
	test: "Test Site One", // last because it's the most generic
}
