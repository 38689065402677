import { useState, useRef, useEffect } from "react"
import { CaptchaElement, SignUpCardWithImage, SignUpForm } from "components"
import "./ForgotPassword.css"
import { Auth } from "@aws-amplify/auth"
import { onError, useQuery, validatePassword } from "libs"
import { useFormFields } from "libs"
import { useLogin } from "libs"
import ReCAPTCHA from "react-google-recaptcha"
import { BsArrowRepeat } from "react-icons/bs"

export function ForgotPassword() {
	const captchaRef = useRef<ReCAPTCHA>(null)
	const query = useQuery()
	const code = query.get("code") || ""
	const email = query.get("email") || ""
	const [isLoading, setIsLoading] = useState(false)
	const [emailSent, setEmailSent] = useState(false)
	const [emailNeedsFilling, setEmailNeedsFilling] = useState(false)
	const [codeResent, setCodeResent] = useState<"sending" | "sent" | "not sending">("not sending")
	const performLogin = useLogin({ responseOnly: false })
	const validationRef = useRef(null)
	const [fields, handleFieldChange, updateFields] = useFormFields({
		email: email,
		code: code,
		newPassword: "",
		confirmNewPassword: "",
	})

	useEffect(() => {
		let timeoutId: NodeJS.Timeout
		if (codeResent === "sent") {
			timeoutId = setTimeout(() => {
				setCodeResent("not sending")
			}, 10000)
		}
		return () => clearTimeout(timeoutId)
	}, [codeResent])

	function validateForm() {
		return fields.email.length > 0
	}

	function validateConfirmationForm() {
		return (
			fields.email.length > 0 &&
			fields.code.length > 0 &&
			validatePassword(fields.newPassword) &&
			fields.newPassword === fields.confirmNewPassword
		)
	}

	async function handleSubmit(event) {
		event.preventDefault()
		setIsLoading(true)

		await Auth.forgotPassword(fields.email)
		setEmailSent(true)
		setIsLoading(false)
	}

	async function onCaptchaChange(validationData) {
		try {
			if (!validationData.token) {
				throw new Error("Captcha failed")
			}
			validationRef.current = validationData
			await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.newPassword)
			await performLogin({ email: fields.email, password: fields.newPassword, validationData: validationRef.current })
		} catch (e) {
			onError(e)
			captchaRef.current.reset()
			setIsLoading(false)
		}
	}
	async function resendCode() {
		if (fields.email.length > 0) {
			try {
				setCodeResent("sending")
				await Auth.forgotPassword(fields.email)
				setCodeResent("sent")
				setEmailNeedsFilling(false)
				updateFields({ code: "" })
			} catch (e) {
				setCodeResent("not sending")
				onError(e)
			}
		} else {
			setEmailNeedsFilling(true)
		}
	}

	async function handleConfirmationSubmit(event) {
		event.preventDefault()
		setIsLoading(true)
		captchaRef.current.execute()
	}
	return (
		<SignUpCardWithImage size="medium">
			{!emailSent && code === "" ? (
				<div className="forgot-password-form-container-1">
					<SignUpForm
						title={
							<>
								<h1>Forgot Password?</h1>
								<h3>No worries, we will send you a reset code via email</h3>
							</>
						}
						button={{
							text: "Send code",
							role: "submit",
							onClick: handleSubmit,
							disabled: !validateForm(),
							isLoading: isLoading,
						}}
						fields={[
							{
								label: "Email",
								type: "email",
								placeholder: "Enter email",
								fieldName: "email",
								initialValue: fields.email,
								autofocus: true,
								autocomplete: "email",
								required: true,
								inputFieldHandler: handleFieldChange,
							},
						]}
					/>
				</div>
			) : (
				<div className="forgot-password-form-container-2">
					<SignUpForm
						title={
							<>
								<h1>Reset Password</h1>
								<h3>Your new password must be different from previously used passwords</h3>
							</>
						}
						button={{
							text: "Reset Password",
							role: "submit",
							onClick: handleConfirmationSubmit,
							disabled: !validateConfirmationForm(),
							isLoading: isLoading,
						}}
						fields={[
							{
								label: "Email",
								type: "email",
								placeholder: "Enter email",
								fieldName: "email",
								initialValue: fields.email,
								disabled: email.length > 0 && fields.email === email,
								autocomplete: "username",
								required: true,
								inputFieldHandler: handleFieldChange,
								description: (
									<span
										style={
											emailNeedsFilling && fields.email.length === 0
												? { color: "var(--ara-error)" }
												: { display: "none" }
										}>
										Fill in the email to resend the code.
									</span>
								),
							},
							{
								label: "Reset Code",
								type: "text",
								inputMode: "numeric",
								placeholder: "Enter reset code",
								fieldName: "code",
								autocomplete: "one-time-code",
								required: true,
								disabled: code.length > 0 && fields.code === code,
								initialValue: fields.code,
								inputFieldHandler: handleFieldChange,
								description: (
									<>
										<span>Please check your inbox/spam</span>
										{codeResent !== "sent" ? (
											<>
												<span> or </span>
												<span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={resendCode}>
													Resend the code {codeResent === "sending" && <BsArrowRepeat className="spinning" />}
												</span>
											</>
										) : (
											<>
												<span>. </span>
												<span style={{ color: "var(--ara-success)" }}>Code resent successfully</span>
											</>
										)}
									</>
								),
							},
							{
								label: "New Password",
								type: "password",
								placeholder: "Create password",
								fieldName: "newPassword",
								autocomplete: "new-password",
								required: true,
								passwordAlert: true,
								initialValue: fields.newPassword,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "Confirm New password",
								type: "password",
								placeholder: "Confirm password",
								fieldName: "confirmNewPassword",
								autocomplete: "new-password",
								required: true,
								initialValue: fields.confirmNewPassword,
								inputFieldHandler: handleFieldChange,
								description: (
									<span
										style={
											fields.newPassword === fields.confirmNewPassword
												? { display: "none" }
												: { color: "var(--ara-error)" }
										}>
										Password & Confirm Password do not match.
									</span>
								),
							},
						]}
					/>
					<div>
						<CaptchaElement ref={captchaRef} onChange={onCaptchaChange} shouldExecuteAfterErrorReset={isLoading} />
					</div>
				</div>
			)}
		</SignUpCardWithImage>
	)
}
