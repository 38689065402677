import { BrowserStorageCache } from "@aws-amplify/cache"
import { API } from "@aws-amplify/api"

let refreshCache: typeof BrowserStorageCache | null = null
let sessionRefreshCache: typeof BrowserStorageCache | null = null

const getRefreshCache = () => {
	refreshCache =
		refreshCache ??
		BrowserStorageCache.createInstance({
			storage: window.localStorage,
			keyPrefix: "refreshCache",
		})
	sessionRefreshCache =
		sessionRefreshCache ??
		BrowserStorageCache.createInstance({
			storage: window.sessionStorage,
			keyPrefix: "refreshCache",
		})
	return [refreshCache, sessionRefreshCache] as const
}

/**
 * store in session and local storage to have a persisted version and a more flexible tab only version
 * @param refreshToken
 * @param expires
 */
export function storeRefreshCode(refreshToken: string, expires: number) {
	const [refreshCache, sessionRefreshCache] = getRefreshCache()
	refreshCache.setItem("refreshToken", refreshToken, { expires })
	sessionRefreshCache.setItem("refreshToken", refreshToken)
}

/**
 * Prefer session storage (tab specific) over local storage
 * @returns
 */
export function retrieveRefreshCode(): string | null {
	const [refreshCache, sessionRefreshCache] = getRefreshCache()
	return sessionRefreshCache.getItem("refreshToken") ?? refreshCache.getItem("refreshToken")
}

export function deleteRefreshCode() {
	const [refreshCache, sessionRefreshCache] = getRefreshCache()
	refreshCache.removeItem("refreshToken")
	// sessionRefreshCache.removeItem("refreshToken")  // this matters less as it is not shared between tabs
}

interface RefreshTokenResponse {
	refreshExpiry: number
	appId: string
	accessToken: `${string}.${string}.${string}`
}

async function refreshToken({ refreshCode }: { refreshCode: string }): Promise<RefreshTokenResponse> {
	const response = await API.get(global.config.apiGatewayLinks.NAME, "/token", {
		headers: { Authorization: refreshCode },
	})
	return response as RefreshTokenResponse
}

export function refreshAraAccessToken(refreshCode?: string) {
	const thisRefreshCode = refreshCode ?? retrieveRefreshCode()
	if (!thisRefreshCode) {
		throw new Error("No refresh token found")
	}
	console.log("Refreshing access token")
	return refreshToken({ refreshCode: thisRefreshCode })
}
