import { useEffect, useState } from "react"
import "./PasswordAlert.css"

const passwordRules = [
	{ description: "8 characters minimum", regex: /.{8,}/ },
	{ description: "1 uppercase", regex: /[A-Z]/ },
	{ description: "1 lower case", regex: /[a-z]/ },
	{ description: "1 number", regex: /[0-9]/ },
	{ description: "1 special character", regex: /[_\W]/ },
]

export function PasswordAlert({
	passwordValue,
	fieldRef,
}: {
	passwordValue: string
	fieldRef: React.RefObject<HTMLInputElement>
}) {
	const [focused, setFocused] = useState({ wasFocused: false, isFocused: false })
	const isPasswordValid = passwordRules.every((rule) => rule.regex.test(passwordValue))

	useEffect(() => {
		const currentFieldRef = fieldRef?.current
		const handleFocus = () => {
			setFocused({ wasFocused: true, isFocused: true })
		}

		const handleBlur = () => {
			setFocused({ wasFocused: true, isFocused: false })
		}

		if (currentFieldRef) {
			currentFieldRef.addEventListener("focus", handleFocus)
			currentFieldRef.addEventListener("blur", handleBlur)
		}

		return () => {
			if (currentFieldRef) {
				currentFieldRef.removeEventListener("focus", handleFocus)
				currentFieldRef.removeEventListener("blur", handleBlur)
			}
		}
	}, [fieldRef])

	return (
		focused.wasFocused && (
			<div className="password-alert">
				<div className="password-alert-title">
					{!focused.isFocused && !isPasswordValid ? (
						<span className={focused ? "error-text" : ""}>Password does not meet the criteria</span>
					) : !isPasswordValid ? (
						<span>Password must contain at least</span>
					) : (
						focused.isFocused && <span>Strong password</span>
					)}
				</div>
				<ul>
					{passwordRules.map(
						(rule, index) =>
							((!focused.isFocused && !isPasswordValid) || focused.isFocused) && (
								<li
									className={`${isPasswordValid ? "valid-requirement" : ""} ${!rule.regex.test(passwordValue) && !focused.isFocused ? "error-text" : ""}`}
									key={index}
									style={{
										color: rule.regex.test(passwordValue) ? "var(--text-grey)" : undefined,
									}}>
									{rule.description}
								</li>
							)
					)}
				</ul>
			</div>
		)
	)
}
