import { useQuery } from "libs"
import { MdOpenInNew } from "react-icons/md"
import { CardView } from "components"

function inIframe() {
	try {
		return window.self !== window.top
	} catch (e) {
		return true
	}
}

export function IframePopOutButton() {
	const query = useQuery()

	const popout = () => {
		window.open(document.location.href)
	}

	const show = inIframe() //&& query.get("popoutbutton") === "true"

	return (
		show && (
			<CardView style={{ position: "fixed", bottom: 20, left: 8, padding: 5 }}>
				<MdOpenInNew
					onClick={popout}
					className="clickable"
					style={{ color: "var(--ara-blue)", width: 25, height: 25 }}></MdOpenInNew>
			</CardView>
		)
	)
}
