import { createContext, useCallback, useContext, useState } from "react"
import { Modal } from "./Modal"

interface ModalContextProps {
	modalContent?: JSX.Element | null
	setModalContent?: (content: JSX.Element | null, options?: ModalOptions) => void
	shouldShowModal: boolean
	modalOptions?: ModalOptions
}

interface ModalOptions {
	clickOutAnywhere?: boolean
	PreCloseScreen?: PreCloseComponent
}

type PreCloseComponent = ({
	stay,
	leave,
}: {
	stay: (e: MouseEvent) => void
	leave: (e: MouseEvent) => void
}) => JSX.Element

const DEFAULT_MODAL_OPTIONS: ModalOptions = {
	clickOutAnywhere: true,
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export const ModalContextProvider = ({
	modalContentInit,
	children,
}: {
	modalContentInit?: JSX.Element | null
	children: React.ReactNode
}) => {
	const [[modalContent, modalOptions], setModalContentBase] = useState<[JSX.Element | null, ModalOptions]>([
		modalContentInit,
		DEFAULT_MODAL_OPTIONS,
	])
	const shouldShowModal = modalContent != null
	const [showPreClose, setShowPreClose] = useState(false)

	const setModalContent = useCallback(
		(content: JSX.Element | null, options?: ModalOptions) => {
			setModalContentBase([content, options == null ? DEFAULT_MODAL_OPTIONS : { ...DEFAULT_MODAL_OPTIONS, ...options }])
		},
		[setModalContentBase]
	)

	return (
		<ModalContext.Provider value={{ modalContent, modalOptions, setModalContent, shouldShowModal }}>
			<div className="working-space">
				{children}
				<Modal showPreClose={showPreClose} setShowPreClose={setShowPreClose}>
					{modalContent}
				</Modal>
			</div>
		</ModalContext.Provider>
	)
}

export const useModalContext = () => useContext(ModalContext)
