import { forwardRef } from "react"
import "./card-view.css"

export const CardView = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, children, ...props }, ref) => {
		return (
			<div ref={ref} className={`${className || ""} CardView`} {...props}>
				{children}
			</div>
		)
	}
)
