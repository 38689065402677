import { LoadingSpinner } from "../LoaderButton/LoaderButton"

export function CenteredStatus({ isLoading = true, children }: { isLoading?: boolean; children?: React.ReactNode }) {
	return (
		<div
			style={{
				display: "flex",
				height: "100%",
				minHeight: "80vh",
				width: "100%",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<LoadingSpinner isLoading={isLoading} />
			<div
				style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "center", justifyContent: "center" }}>
				{children}
			</div>
		</div>
	)
}
