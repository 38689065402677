import hireAraBlueLogo from "../../../images/HireAraStampBlue.png"
import "./SignUpCard.css"

interface SignUpCardProps {
	children: React.ReactNode
	logo?: boolean
	size?: "small" | "large" | "medium"
	imageContainerStyle?: React.CSSProperties
	image: React.ReactNode
}

export function SignUpCard({ image, children, size = "small", logo = false, imageContainerStyle }: SignUpCardProps) {
	return (
		<div className={`card-sign-up card-sign-up-${size}`}>
			{logo && <img className="blue-logo" src={hireAraBlueLogo} alt="HireAra blue logo" />}
			<div className="card-sign-up-image-container" style={imageContainerStyle}>
				{image}
			</div>
			<div className="card-sign-up-content">{children}</div>
		</div>
	)
}
